// THIS IS THE ARRAY USED BY THE FILTER
// CRITICAL FOR FRONTEND, DON'T DELETE!

const regionList = [
  {
    name: "Caribbean",
    Celebrity: ["Short Caribbean", "Long Caribbean", "7N Caribbean"],
    "Royal Caribbean": [
      "7N CARIBBEAN",
      "SHORT CARIBBEAN",
      "LONG CARIBBEAN",
      "CARIBNE",
    ],
    Virgin: ["Caribbean"],
  },
  {
    name: "Europe",
    Celebrity: ["Europe"],
    "Royal Caribbean": ["EUROPE"],
    Virgin: ["Mediterranean", "Northern Europe", "Western Europe"],
  },
  {
    name: "Repositioning/Transatlantic",
    Celebrity: ["Repos/Trnatl"],
    "Royal Caribbean": ["REPOS/TRNATL"],
    Virgin: ["Transatlantic"],
  },
  {
    name: "Canals",
    Celebrity: ["Canals"],
    "Royal Caribbean": ["CANALS"],
    Virgin: ["Panama Canal"],
  },
  {
    name: "Bermuda",
    Celebrity: ["Bermuda"],
    "Royal Caribbean": ["BERMUDA"],
  },
  {
    name: "Hawaii",
    Celebrity: ["Hawaii"],
    "Royal Caribbean": ["HAWAII"],
  },
  {
    name: "Asia",
    Celebrity: ["Asia"],
  },
  {
    name: "Australia/New Zealand/South Pacific",
    Celebrity: ["Aust/Nzl/Sopac"],
    "Royal Caribbean": ["AUST/NZL/SOPAC"],
  },
  {
    name: "Alaska",
    Celebrity: ["Alaska"],
    "Royal Caribbean": ["ALASKA"],
    Virgin: ["Alaska"],
  },
  {
    name: "Canada",
    Celebrity: ["Canada"],
    "Royal Caribbean": ["CANADA"],
    Virgin: ["Canada"],
  },
  {
    name: "South America",
    Celebrity: ["Samerica"],
    Virgin: ["South America"],
  },
  {
    name: "West Coast US",
    "Royal Caribbean": ["WESTCOASTSHORT"],
    Virgin: ["West Coast US"],
  },
  {
    name: "Mexico",
    "Royal Caribbean": ["MEXICO"],
  },
  {
    name: "East Coast US",
    Virgin: ["East Coast US"],
  },
];

module.exports = regionList;
