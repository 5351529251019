import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerUser } from "../store/thunks/registerUser";
import Logo from "../components/Logo";
import FlowRider from "../assets/FlowRider.webp";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import { isDev } from "../config";
import PasswordRequirements from "../components/PasswordRequirements";
import { XCircleIcon } from "@heroicons/react/24/outline";

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [clientUserAgent, setClientUserAgent] = useState(""); // Client User Agent
  const [fbc, setFbc] = useState(""); // Click ID (fbc) Cookie
  const referrer = Cookies.get("referrer");
  const spamPattern = /(https?:\/\/|bit\.ly)/i;

  useEffect(() => {
    setClientUserAgent(navigator.userAgent);
    // Fetch the fbc cookie value
    const fbcCookieValue =
      document.cookie
        .split("; ")
        .find((row) => row.startsWith("_fbc="))
        ?.split("=")[1] || "";

    setFbc(fbcCookieValue);
  }, []);

  let formData = {
    user_type: "user",
    first_name: firstName,
    last_name: lastName,
    email: email,
    password: password,
    username: email,
    client_user_agent: clientUserAgent, // Add client_user_agent to formData
    fbc: fbc, // Add fbc to formData
    referrer: referrer,
  };

  const isValidEmail = (email) => {
    // Simple regex pattern for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const isValidPassword = (password) => {
    // Minimum password length
    if (password.length < 8) {
      return false;
    }

    // Check if the password contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return false;
    }

    // Check if the password contains at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return false;
    }

    // Check if the password contains at least one digit
    if (!/\d/.test(password)) {
      return false;
    }

    // Check if the password contains at least one special character
    if (!/[@$!%*?&]/.test(password)) {
      return false;
    }

    // All checks passed, the password is valid
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for spam in first and last name fields
    if (spamPattern.test(formData.first_name) || spamPattern.test(formData.last_name)) {
      setError("Spam detected in name fields. Please use valid input.");
      return;
    }

    // Email validation
    if (!isValidEmail(formData.email)) {
      // Assuming formData.email contains the email
      setError("Invalid email format. Please check your email.");
      return; // Return early
    }

    if (!isValidPassword(formData.password)) {
      setError(
        "Password must be greater than 7 characters and contain 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character."
      );
      return;
    }

    setError(null);
    setClientUserAgent(navigator.userAgent);

    try {
      const response = await dispatch(registerUser(formData));

      if (response.payload && response.payload._id) {
        console.log("Registration successful");
        if (!isDev) {
          ReactGA.event({
            category: "User",
            action: "Created an Account",
          });
        }
        navigate("/dashboard");
      } else {
        console.log(
          "Registration failed. Unexpected payload:",
          response.payload
        );
        setError("Registration failed. Please check your information.");
      }
    } catch (error) {
      setError(error.message);
      console.log("Error:", error);
      alert(error.message);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <Logo />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Create An Account
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Already Registered?{" "}
                <a
                  href="/login"
                  className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Sign In Now
                </a>
              </p>
            </div>

            {error && (
              <div className="mt-4 flex items-center">
                <p className="text-red-500 mr-2">{error}</p>
                <button
                  className="text-red-500 font-semibold"
                  onClick={() => setError("")}>
                  Dismiss
                </button>
              </div>
            )}

            <div className="mt-10">
              <div>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      First Name
                    </label>
                    <div className="mt-2">
                      <input
                        id="first_name"
                        name="first_name"
                        type="text"
                        autoComplete="given-name"
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Last Name
                    </label>
                    <div className="mt-2">
                      <input
                        id="last_name"
                        name="last_name"
                        type="text"
                        autoComplete="family-name"
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <PasswordRequirements password={password} />

                  {/* <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <XCircleIcon
                          className="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">
                          Our servers are currently down for maintenance.
                        </h3>
                      </div>
                    </div>
                  </div> */}

                  <div>
                    <button
                      type="button" // Use type="button" to prevent form submission
                      onClick={handleSubmit}
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      Create Account
                    </button>
                  </div>

                  <p className="mt-10 text-center text-sm text-gray-500">
                    After creation, check your email for a verification link.
                  </p>
                  <br />
                  <div>
                    <p className="mt-2 text-sm leading-6 text-gray-500">
                      By clicking Create Account you agree to our
                    </p>
                    <a
                      href="/privacy_policy"
                      target="_blank"
                      className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Privacy Policy
                    </a>
                    &nbsp;&&nbsp;
                    <a
                      href="/usage_terms"
                      target="_blank"
                      className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Usage Terms
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={FlowRider}
            alt=""
          />
        </div>
      </div>
    </>
  );
}

export default Register;
